@font-face {
  font-family: RobotoCondensed;
  src: url(../public/font/RobotoCondensed-Regular.ttf);
}

@font-face {
  font-family: PaintShop;
  src: url(../public/font/dallas-print-shop-sans.ttf);
}





:root {
  --navbar-height: 4.5em;

  /* light */
  /* --background-color:#d4d4d4;
  --background-color-highlight:#a3a3a3;
  --background-color-light:#8f8f8f;
  --background-color-accent:#dab42c;
  --font-color:#3a3a3a; */

  /* dark */
  --background-color:#2c2c2c;
  --background-color-light:#4b4b4b;
  --background-color-highlight:#303030;
  --background-color-accent:#dab42c;
  --font-color:#dfdfdf; 

  --font-to-use:"PaintShop";



  --banner-image:url("../public/images/home/banner.jpg");
}

body{
  background-color: var(--background-color);
  overflow-y: scroll;
}

.swiper-button-next,
.swiper-button-prev {
    background-color: rgba(87, 87, 87, 0.5);
    right:10px;
    padding: 30px;
    color: var(--background-color-accent) !important;
    fill: black !important;
    stroke: black !important;
}

.navbar{
  width:100%;
  height:var(--navbar-height);
  background-color: var(--background-color);
  color:var(--font-color);
  position: fixed;
  font-family: var(--font-to-use);
  letter-spacing: 2.5px;
  z-index: 100;
}


.navbar .logoName {
  color:var(--background-color-accent);
  text-align: center;
  display: inline-block;
  justify-content: center;
  margin-left: 2%;
}

.navbar .logoName a{
  color:var(--background-color-accent);
  text-decoration: none;
}



.navbar .logo{
  height:var(--navbar-height);
  display: inline-block;
  vertical-align: bottom;
  margin-right: 7.5%;
  margin-left:3%;
}

.navbar .button{
  background-color:var(--background-color);
  color:var(--font-color);
  
  height:var(--navbar-height);
  text-align: center;
  line-height: var(--navbar-height);
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-right:2%;
}

.navbar .button:hover{
  background-color: var(--background-color-highlight);
}

.content{
  padding-top:var(--navbar-height);
  background-color: var(--background-color-light);
  overflow: hidden;
}

.imageDisplay{
  width: 100%;
  height:55em;
  background-image:var(--banner-image);
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  text-align: center;
}

.imageDisplay div{
  width:100%;
  height: 100%;
  background-color: rgba(40, 40, 40, 0.75);
  display: inline-block;
  border-color: #303030;
}
.imageDisplay h1{
  margin-top: 15%;
  color: var(--background-color-accent);
  font-family: var(--font-to-use);
  font-size: 5em;
  font-weight: normal;
}

.imageDisplay p{
  color: var(--font-color);
  font-family: var(--font-to-use);
  font-size: 2.5em;
  text-decoration: none;
}



.textDisplay{
  text-align: center;
  display: table;
  background-color:var(--background-color-highlight);
  height: 10em;
  width: 100%;
  color: var(--font-color);
  padding-top: 1em;
  padding-bottom: 5em;
}

.textDisplay h1{
  letter-spacing: 0.1em;
}

.textDisplay .text{
  display: table-cell;
  vertical-align: middle;
  font-family: var(--font-to-use);
  font-size: 25px;
}

.footer{
  width:100%;
  background-color: var(--background-color);
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

.creatorFooter{
  color:var(--background-color-light);
  font-family:"RobotoCondensed";
  vertical-align: top;
  text-align: center;
  font-size: 0.75em;
  letter-spacing: 0.25em;
}

.footer .box{
  width: 25%;
  display: inline-block;
  color:var(--font-color);
  font-family: var(--font-to-use);
  margin-top:5em;
  padding-bottom:5em;
  vertical-align: top;
  text-align: center;
}


.footer .box ul{
  padding: 0;
  list-style-type: none;
  display: inline-block;
}

.footer .box li{
  margin-top: 20px;
}

.footer .box a{
  color: var(--font-color);
  font-family: var(--font-to-use);
  text-decoration: none;
}

.carousel{
  height:30em;
  overflow: hidden;
  background-color: var(--background-color-light);
  color:var(--font-color);
}

.carousel .swiper {
  height: 100%;
}

.card {
  width:100%;
  height: 100%;
  float:left;
  font-family: var(--font-to-use);
  font-size: 2em;
}
.card .text{
  height: 100%;
  width:38%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  padding-left:6%;
  padding-right:6%;
}

.card .text .header{
  text-align: center;
}
.card .image{
  height: 100%;
  width:50%;
  display: inline-block;
  
}

.card .header{
  font-size: larger;
}

.card .image img{
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectsHeader{
  font-family: var(--font-to-use);
  color: var(--font-color);
  text-align: center;
  letter-spacing: 0.7em;
  font-size: larger;
}

.projectDisplay{
  width: 90%;
  margin-top: 4em;
  margin-bottom: 4em;
  margin-left:5%;
  margin-right:5%;
  background-color: var(--background-color-light);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.projectsBanner{
  height: 30em;
  background-image: var(--banner-image);
  background-size: cover;
  background-repeat: no-repeat;
}

.projectsTitle{
  background-color: var(--background-color-accent);
  text-align: center;
  font-family: var(--font-to-use);
  color:var(--font-color);
  padding-top: 1px;
  padding-bottom: 1px;
}

.projectsTitle h1{
  font-size: 5em;
}

.projectCard{
  border-radius: 20px;
  width: 32em;
  height: 20em;
  display: inline-block;
  margin-right: 1em;
  margin-left: 1em;
  margin-bottom: 2em;
  text-align: center;
  position: relative;
  overflow: hidden;
  color:var(--font-color);
  font-family: var(--font-to-use);
  z-index: 20;
}

.projectCard:hover .cover {
  width:0%;
}

.projectCard .cover{
  position: absolute;
  background-color: rgba(31, 31, 30, 0.75);
  top:0;
  left:0;
  width:100%;
  height:100%;
  transition: width 1s;
}


.projectCard .wrapper .cover .content{
    
  white-space: nowrap;
  overflow: hidden;
  margin-top: 30%;
  padding-bottom: 1em;
  font-size: x-large;
  height: 5%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
}

.projectCard .description{
  width: 80%;
  vertical-align:middle;
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  height: 40%;
}

.projectCard .viewMore{
  height: 5%;
}

.projectCard .image{
  position:absolute; 
  top:0; 
  left:0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.projectCard .wrapper .image{
  z-index: -1;
}

.projectCard .wrapper .image img{
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectCard .viewMore{
  text-decoration: none;
  color: var(--font-color);
}

.projectDiv{
  width: 100%;
  height: 36em;
  color:var(--font-color);
  display: block;
}

.projectImage{
  width: 50%;
  height: 36em;
  float: left;
}

.projectText{
  width: 50%;
  float: right;
  background-color: var(--background-color);
  font-family: var(--font-to-use);
  color:var(--font-color);
}

.projectText div{
  padding-left:2em;
}
.projectText h1{
  font-size: 2.5em;
}

.projectText p{
  font-size: 1em;
}

.headerBanner{
  width: 100%;
  font-family: var(--font-to-use);
  color:var(--font-color);
  background-color: var(--background-color);
  font-weight: normal;
  font-size: 3em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

.projectDivImages{
  width: 75%;
  margin-left: 12.5%;
  margin-right: 12.5%;
  height: 100%;
  width: 100%;
}

.projectImage img{
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectDivImages .imageDiv{
  width:30em;
  height:20em;
  margin: '1%';
  display: inline-block;
  padding-bottom: 2em;
  padding-right: 1em;
  
}

.projectDivImages img{
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projectDivImages img:hover{
  cursor:pointer;
}


.imageViewer div{
  z-index: 20;
}

.contactText{
  color:var(--font-color);
  font-family: var(--font-to-use);
  text-align: center;
  margin-bottom: 2em;
}

.contactBlock{
  width: 12em;
  height: 14em;
  padding: 10px;
  color:var(--font-color);
  font-family: var(--font-to-use);
  background-color: var(--background-color);
  background: linear-gradient( var(--background-color) 62%, var(--background-color-accent) 200%);
  margin-left:1em;
  margin-right:1em;
  display: inline-block;
  margin:0,auto;
  border-radius: 10%;
  margin-bottom: 5%;
}

.contactBlock .image{
  border-radius: 100%;
  overflow: hidden;
  height: 80%;
  width: 100%;
}

.contactBlock .image img{
  
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contactBlock .text{
  text-align: center;
  color:var(--font-color);
  font-family: var(--font-to-use);
  text-align: center;
  word-wrap: break-word;
  font-size: 1em;
}

.contactBlockContianer{
  margin-left: 12.5%;
  margin-right: 12.5%;
  align-content: center;
  display: flex;
  justify-content: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--background-color-highlight);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--background-color-accent);
}

@media (max-width: 800px) {

  :root {
    --navbar-height: 50px;
  
    /* light */
    /* --background-color:#d4d4d4;
    --background-color-highlight:#a3a3a3;
    --background-color-light:#8f8f8f;
    --background-color-accent:#dab42c;
    --font-color:#3a3a3a; */
  
    /* dark */
    --background-color:#2c2c2c;
    --background-color-light:#4b4b4b;
    --background-color-highlight:#303030;
    --background-color-accent:#dab42c;
    --font-color:#dfdfdf; 
  
    --font-to-use:"PaintShop";
  
  
  
    --banner-image:url("../public/images/home/banner.jpg");
  }
  
  body{
    background-color: var(--background-color);
    overflow-y: scroll;
  }
  
  .swiper-button-next,
  .swiper-button-prev {
      background-color: rgba(87, 87, 87, 0.5);
      right:10px;
      padding: 30px;
      color: var(--background-color-accent) !important;
      fill: black !important;
      stroke: black !important;
  }
  
  .navbar{
    width:100%;
    height:var(--navbar-height);
    background-color: var(--background-color);
    color:var(--font-color);
    position: fixed;
    font-family: var(--font-to-use);
    letter-spacing: 2.5px;
    z-index: 100;
  }
  
  
  .navbar .logoName {
    color:var(--background-color-accent);
    text-align: center;
    display: inline-block;
    justify-content: center;
    margin-left: 2%;
  }
  
  .navbar .logoName a{
    color:var(--background-color-accent);
    text-decoration: none;
  }
  
  
  
  .navbar .logo{
    height:var(--navbar-height);
    display: inline-block;
    vertical-align: bottom;
    margin-right: 7.5%;
    margin-left:3%;
    position: absolute;
    z-index: -1;
  }
  
  .navbar .button{
    background-color: rgba(0, 0, 0, 0);
    color:var(--font-color);
    
    height:var(--navbar-height);
    text-align: center;
    line-height: var(--navbar-height);
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-right:2%;
    z-index: 20;
  }
  
  .navbar .button:hover{
    background-color: rgba(0, 0, 0, 0);
  }
  
  .content{
    padding-top:var(--navbar-height);
    background-color: var(--background-color-light);
    overflow: hidden;
  }
  
  .imageDisplay{
    width: 100%;
    height:25em;
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden;
    text-align: center;
  }
  
  .imageDisplay div{
    width:100%;
    height: 100%;
    background-color: rgba(40, 40, 40, 0.75);
    display: inline-block;
    border-color: #303030;
  }
  .imageDisplay h1{
    margin-top: 150px;
    color: var(--background-color-accent);
    font-family: var(--font-to-use);
    font-size: 2em;
    font-weight: normal;
  }
  
  .imageDisplay p{
    color: var(--font-color);
    font-family: var(--font-to-use);
    font-size: 1.5em;
    text-decoration: none;
  }
  
  
  
  .textDisplay{
    text-align: center;
    display: table;
    background-color:var(--background-color-highlight);
    height: 10em;
    width: 100%;
    color: var(--font-color);
    padding-top: 1em;
    padding-bottom: 5em;
  }
  
  .textDisplay h1{
    letter-spacing: 0.1em;
  }
  
  .textDisplay .text{
    display: table-cell;
    vertical-align: middle;
    font-family: var(--font-to-use);
    font-size: 12px;
  }
  
  .footer{
    font-size: 10px;
    width:100%;
    background-color: var(--background-color);
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .creatorFooter{
    color:var(--background-color-light);
    font-family:"RobotoCondensed";
    vertical-align: top;
    text-align: center;
    font-size: 0.75em;
    letter-spacing: 0.25em;
  }
  
  .footer .box{
    width: 25%;
    height: 200px;
    display: inline-block;
    color:var(--font-color);
    font-family: var(--font-to-use);
    margin-top:5em;
    padding-bottom:5em;
    vertical-align: top;
    text-align: center;
  }
  
  
  .footer .box ul{
    padding: 0;
    list-style-type: none;
    display: inline-block;
  }
  
  .footer .box li{
    margin-top: 20px;
  }
  
  .footer .box a{
    color: var(--font-color);
    font-family: var(--font-to-use);
    text-decoration: none;
  }
  
  .carousel{
    height:15em;
    overflow: hidden;
    background-color: var(--background-color-light);
    color:var(--font-color);
  }
  
  .carousel .swiper {
    height: 100%;
  }
  
  .card {
    width:100%;
    height: 100%;
    float:left;
    font-family: var(--font-to-use);
    font-size: 15px;
  }
  .card .text{
    height: 100%;
    width:38%;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding-left:6%;
    padding-right:6%;
  }
  
  .card .text .header{
    text-align: center;
  }
  .card .image{
    height: 100%;
    width:50%;
    display: inline-block;
    
  }
  
  .card .header{
    font-size: larger;
  }
  
  .card .image img{
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .projectsHeader{
    margin-top: var(--navbar-height);
    font-family: var(--font-to-use);
    color: var(--font-color);
    text-align: center;
    letter-spacing: 0.7em;
    font-size: 10px;
  }
  
  .projectDisplay{
    width: 90%;
    margin-top: 4em;
    margin-bottom: 4em;
    margin-left:5%;
    margin-right:5%;
    background-color: var(--background-color-light);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  .projectsBanner{
    height: 30em;
    background-image: var(--banner-image);
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .projectsTitle{
    background-color: var(--background-color-accent);
    text-align: center;
    font-family: var(--font-to-use);
    color:var(--font-color);
    padding-top: 1px;
    padding-bottom: 1px;
  }
  
  .projectsTitle h1{
    font-size: 5em;
  }
  
  .projectCard{
    border-radius: 20px;
    width: 32em;
    height: 20em;
    display: inline-block;
    margin-right: 1em;
    margin-left: 1em;
    margin-bottom: 2em;
    text-align: center;
    position: relative;
    overflow: hidden;
    color:var(--font-color);
    font-family: var(--font-to-use);
    z-index: 20;
  }
  
  .projectCard:hover .cover {
    width:0%;
  }
  
  .projectCard .cover{
    position: absolute;
    background-color: rgba(31, 31, 30, 0.75);
    top:0;
    left:0;
    width:100%;
    height:100%;
    transition: width 1s;
  }
  
  
  .projectCard .wrapper .cover .content{
      
    white-space: nowrap;
    overflow: hidden;
    margin-top: 30%;
    padding-bottom: 1em;
    font-size: x-large;
    height: 5%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0);
  }
  
  .projectCard .description{
    width: 80%;
    vertical-align:middle;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
    height: 40%;
  }
  
  .projectCard .viewMore{
    height: 5%;
  }
  
  .projectCard .image{
    position:absolute; 
    top:0; 
    left:0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .projectCard .wrapper .image{
    z-index: -1;
  }
  
  .projectCard .wrapper .image img{
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .projectCard .viewMore{
    text-decoration: none;
    color: var(--font-color);
  }
  
  .projectDiv{
    width: 100%;
    height: 36em;
    color:var(--font-color);
    display: block;
  }
  
  .projectImage{
    width: 100%;
    height: 30em;
    float: none;
    position: absolute;
    
  }
  
  .projectText{
    width: 100%;
    height: 30em;
    float: none;
    background-color: rgba(40, 40, 40, 0.75);
    font-family: var(--font-to-use);
    color:var(--font-color);
    position: absolute;
  }
  
  .projectText div{
    padding-left:2em;
  }
  .projectText h1{
    font-size: 2.5em;
  }
  
  .projectText p{
    font-size: 1em;
  }
  
  .headerBanner{
    width: 100%;
    font-family: var(--font-to-use);
    color:var(--font-color);
    background-color: var(--background-color);
    font-weight: normal;
    font-size: 3em;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
  }
  
  .projectDivImages{
    width: 75%;
    margin-left: 0%;
    margin-right: 0%;
    height: 100%;
    width: 100%;
  }
  
  .projectImage img{
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .projectDivImages .imageDiv{
    width:100%;
    height:20em;
    margin: '1%';
    display: inline-block;
    padding-bottom: 2em;
    padding-right: 1em;
    
  }
  
  .projectDivImages img{
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .projectDivImages img:hover{
    cursor:pointer;
  }
  
  
  .imageViewer div{
    z-index: 20;
  }
  
  .contactText{
    color:var(--font-color);
    font-family: var(--font-to-use);
    text-align: center;
    margin-bottom: 2em;
  }
  
  .contactBlock{
    width: 33%;
    height: auto;
    padding: 10px;
    font-size: 10px;
    color:var(--font-color);
    font-family: var(--font-to-use);
    background-color: var(--background-color);
    background: linear-gradient( var(--background-color) 62%, var(--background-color-accent) 200%);
    margin-left:1em;
    margin-right:1em;
    display: inline-block;
    margin:0,auto;
    border-radius: 10%;
    margin-bottom: 5%;
  }
  
  .contactBlock .image{
    border-radius: 100%;
    overflow: hidden;
    height: 80%;
    width: 100%;
  }
  
  .contactBlock .image img{
    
    vertical-align: top;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .contactBlock .text{
    text-align: center;
    color:var(--font-color);
    font-family: var(--font-to-use);
    text-align: center;
    word-wrap: break-word;
    font-size: 1em;
  }
  
  .contactBlockContianer{
    margin-left: 12.5%;
    margin-right: 12.5%;
    align-content: center;
    display: flex;
    justify-content: center;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--background-color);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--background-color-highlight);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--background-color-accent);
  }
  
}
